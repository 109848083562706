import { libraryLoaded } from '@exp/exp-utils/helper/window';
import { getWindow, getDocument, isSupported } from '@exp/exp-utils/helper/browser';
import { log, error } from '@exp/exp-utils/helper/logger';

import SchemaHelper from '@exp/exp-utils/schema/schemaHelper';

import { getPerformanceData } from '@tcc/shared/src/performance/performance';

import config from '@tcc/shared/src/helpers/config';
import trackingValues from '@tcc/shared/src/traffic/trackingValues';
import loadHelper from '@tcc/shared/src/helpers/load';

import { writePolicyCookie } from './helpers/policy';
import { handleOrderConfirmation } from './helpers/ecomm';
import { init as initConfigManager } from '@tcc/shared/src/configManager';
import { init as initEventSink } from '@tcc/shared/src/traffic/eventSend';
import { init as initExpDataLayer } from '@tcc/shared/src/experiments/experimentDataLayer';
import { init as initTrafficDataLayer } from '@tcc/shared/src/traffic/trafficDataLayer';
import { init as initEventListener } from '@tcc/shared/src/events/eventListener';
import { init as initSplitIo } from './splitio/splitio';
import { init as initTealium } from './tealium/tealium';
import { initGtag, initDatalayer as initGaDatalayer } from './ga/ga';
import { init as initTTI } from './tti/tti';

import page from '@tcc/shared/src/traffic/eventPageProperties';
import { initializeReferrer } from '@tcc/shared/src/traffic/eventProperties';
import experimentSchema from './experiments/experimentSchema';
import VisitHelperTcc from './helpers/visitHelperTcc';

// Initialize the whole shootin match.  Note that order is important for data layer init
let initialized = false;

const _tccInit = () => {
  if (!initialized) {
    initialized = true;

    // Load order below is *IMPORTANT*

    // Delay push to GA, Traffic, and Tealium sinks until load event
    loadHelper.init(
      getDocument().readyState === 'complete',
      (triggerOnLoad) => {
        getWindow().addEventListener('load', triggerOnLoad);
      }
    );

    // Set the referrer property before GA is initialized
    // Prevents a race condition where GA config and be created before
    // the first pageview event is process and sets the referrer in the property page.
    initializeReferrer(page);

    // Ensure the GA datalayer is initialized with a config record
    // before gtag has a chance to download.
    initGaDatalayer();

    // GA will only be initialized after the document has loaded and
    // will not be loaded for pass (productivity) pages
    if (config.get('tcc.realm') !== 'pass') {
      loadHelper.registerOnLoadFn(initGtag);
    }

    // Populates visit GUIDs immediately if missing
    const visitHelper = new VisitHelperTcc();

    // Traffic events will only be sent after the document has loaded.
    loadHelper.registerOnLoadFn(() => {
      initEventSink(visitHelper);
    });

    // Tealium events will only be sent after the document has loaded.
    loadHelper.registerOnLoadFn(initTealium);

    // Load TTI library if not disabled by page
    if (config.get('tcc.loadTTI')) {
      loadHelper.registerOnLoadFn(initTTI);
    }

    // Applications can force SplitIO to initialize immediately
    if (config.get('tcc.loadSplitIO')) {
      initSplitIo();
    }

    // Expose tracking values on the window
    getWindow()._tccTrackingValues = trackingValues.properties;

    // Load Exp first as trfq commands will push to the ExperimentDataLayer
    initExpDataLayer(new SchemaHelper(experimentSchema), visitHelper);
    initTrafficDataLayer();

    initEventListener();
    loadHelper.registerOnLoadFn(handleOrderConfirmation);

    if (!config.get('tcc.manualPagePerf')) {
      // Only send performance data after the document has loaded
      loadHelper.registerOnLoadFn(() => {
        getPerformanceData('auto');
      });
    }

    log('TCC STARTED', config.getProperties());
  }
};

// Only load if the browser is supported
if (isSupported()) {
  // Load library only once on page... Throw an error if attempted to load more then once
  if (libraryLoaded()) {
    error('TCC Library has already been loaded on page');
  } else {
    // Initialize configs so that we can determine if the library was disabled
    initConfigManager();

    // Set the policy cookie
    writePolicyCookie();

    // Do not init if turned off
    if (config.get('tcc.status') !== 'off') {
      // We should init only when visible
      const doc = getDocument();
      if (doc.visibilityState !== 'prerender') {
        _tccInit();
      } else {
        doc.addEventListener('visibilitychange', () => {
          if (doc.visibilityState !== 'prerender' && doc.visibilityState !== 'unloaded') {
            _tccInit();
          }
        });
      }
    }
  }
}
